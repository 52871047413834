import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import axios from "axios";
import Installer from "./components/Installer";
import Customer from "./components/Customer";
import DemoWebsite from "./components/DemoWebsite";
import Loading from "./components/Loading";
import { defaultInstance } from "./utils/constants";
import { AppContext } from "./context";
import NotFound from "./components/NotFound";
import ErrorPage from "./components/ErrorPage";
import FavIcon from "./assets/bubble.svg";

function App() {
  const [instanceInfo, setInstanceInfo] = useState(defaultInstance);
  const [originalInstanceInfo, setOriginalInstanceInfo] =
    useState(defaultInstance);
  const [isLoading, setIsLoading] = useState(true);
  const [feedbackIsAdded, setFeedbackIsAdded] = useState(false);
  const [typeOfDemo, setTypeOfDemo] = useState("");
  const [error, setError] = useState(null);
  const [view, setView] = useState("Installer");

  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const changeInstanceLanguage = (language) => {
    setInstanceInfo({
      ...instanceInfo,
      mainLanguage: language,
    });
  };

  const handleViewChange = useCallback(
    (e) => {
      const newView = e.target.checked ? "Customer" : "Installer";
      setView(newView);
      const preViewPath = location.pathname.startsWith("/testing")
        ? "/testing"
        : "";
      const viewPath =
        preViewPath + (newView === "Customer" ? "/customer" : "/installer");
      handleNavigation(viewPath);
    },
    [location.pathname, setView, handleNavigation]
  );

  useEffect(() => {
    if (location.pathname.startsWith("/demo")) {
      // Extract parameters for demo route
      const queryParams = new URLSearchParams(location.search);

      // Extract query parameters
      const instanceName = queryParams.get("instanceName");
      const website = queryParams.get("website");
      const logoUrl = queryParams.get("logo");
      const questions = queryParams.get("questions");

      // Function to validate URL
      const isValidUrl = (urlString) => {
        try {
          return Boolean(new URL(urlString));
        } catch (_) {
          return false;
        }
      };

      // Validate logo URL
      const validLogoUrl = logoUrl && isValidUrl(logoUrl) ? logoUrl : null;

      // Set instance info
      setInstanceInfo((prevInfo) => ({
        ...prevInfo,
        instanceName: instanceName || prevInfo.instanceName,
        website: website || prevInfo.website,
        logo: validLogoUrl || prevInfo.logo,
        questions: questions || prevInfo.questions,
      }));
      setTypeOfDemo("Demo");
      setIsLoading(false);
      return () => {};
    }

    setIsLoading(true);
    const instanceId = process.env.REACT_APP_INSTANCE_ID;
    const environment = process.env.REACT_APP_ENV;

    // Check if instanceId is not available
    if (!instanceId) {
      setInstanceInfo(defaultInstance);
      setIsLoading(false);
      return;
    }

    let api;
    switch (environment) {
      case "development":
        api = "http://localhost:9000/instance";
        break;
      case "staging":
        api = "https://admin-api.service-assistant-staging.com/instance";
        break;
      case "production":
        api = "https://admin-api.service-assistant.ai/instance";
        break;
      default:
        console.warn(`Unsupported environment: ${environment}`);
        setIsLoading(false); // Set loading state to false if environment is unsupported
        return; // Exit useEffect early
    }

    // Ensure api is defined before proceeding
    if (!api) {
      console.error("API URL could not be determined.");
      setIsLoading(false);
      return;
    }

    const sendData = {
      instanceId: instanceId,
    };

    axios
      .get(api, {
        params: sendData,
      })
      .then((response) => {
        setInstanceInfo(response.data);
        setOriginalInstanceInfo(response.data);
        setTypeOfDemo(response.data.typeOfDemo || "Both");
        setView(
          response.data.typeOfDemo === "Both"
            ? "Installer"
            : response.data.typeOfDemo
        );
        const pageLinks = document.getElementsByTagName("link");
        const pageLink = Array.from(pageLinks).find((link) => {
          return link.rel === "icon";
        });
        if (pageLink && response?.data?.favIcon) {
          pageLink.href = response.data.favIcon;
        } else {
          pageLink.href = FavIcon;
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === "/customer") {
      setView("Customer");
    } else if (currentPath === "/installer") {
      setView("Installer");
    }
  }, [location.pathname]);

  useEffect(() => {
    // Redirect to a valid route based on typeOfDemo
    if (
      typeOfDemo === "Installer" &&
      location.pathname !== "/" &&
      location.pathname !== "/testing"
    ) {
      window.history.replaceState(null, "", "/");
    } else if (
      typeOfDemo === "Customer" &&
      location.pathname !== "/" &&
      location.pathname !== "/testing"
    ) {
      window.history.replaceState(null, "", "/");
    } else if (typeOfDemo === "Both" && location.pathname === "/") {
      window.history.replaceState(null, "", "/installer");
    }
  }, [typeOfDemo, location.pathname]);

  const demoProps = useMemo(() => {
    return {
      logo: instanceInfo.logo,
      typeOfDemo,
      view,
      handleViewChange,
      feedbackIsAdded,
      variables: instanceInfo,
      setFeedbackIsAdded,
    };
  }, [
    instanceInfo,
    view,
    typeOfDemo,
    handleViewChange,
    feedbackIsAdded,
    setFeedbackIsAdded,
  ]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <ErrorPage statusCode={error.response?.status} message={error.message} />
    );
  }

  return (
    <AppContext.Provider
      value={{ instanceInfo, changeInstanceLanguage, originalInstanceInfo }}
    >
      <div>
        <link
          rel="preload"
          href="../public/fonts/Poppins-Regular.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="../public/fonts/Poppins-Medium.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="../public/fonts/Poppins-SemiBold.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="../public/fonts/Poppins-Bold.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <Routes>
          {typeOfDemo === "Installer" && (
            <>
              <Route path="/" element={<Installer {...demoProps} />} />
              <Route
                path="/testing"
                element={<Installer {...demoProps} isTesting />}
              />
            </>
          )}
          {typeOfDemo === "Customer" && [
            <Route path="/" element={<Customer {...demoProps} />} />,
            <Route
              path="/testing"
              element={<Customer {...demoProps} isTesting />}
            />,
          ]}
          {typeOfDemo === "Both" && (
            <>
              <Route path="/" element={<Navigate to="/installer" />} />
              <Route
                path="/testing"
                element={<Navigate to="/testing/installer" />}
              />
              <Route path="/installer" element={<Installer {...demoProps} />} />
              <Route
                path="/testing/installer"
                element={<Installer {...demoProps} isTesting />}
              />
              <Route path="/customer" element={<Customer {...demoProps} />} />
              <Route
                path="/testing/customer"
                element={<Customer {...demoProps} isTesting />}
              />
            </>
          )}
          <Route path="/demo" element={<DemoWebsite />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </AppContext.Provider>
  );
}

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
