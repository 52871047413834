import { useState, useEffect, useMemo, useContext } from "react";
import axios from "axios";
import { handleSendResult, handleFeedbackSubmit } from "../zaps/zaps";
import {
  getBrightness,
  audioBlobToBase64,
  formatDeviceName,
  checkLimit,
  translateQuestions,
  replaceQuestions,
} from "../utils";
import StepsComponent from "./StepsComponent";
import NoResults from "./NoResults";
import ProgressBar from "./ProgressBar";
import VoiceAnimation from "./VoiceAnimation";
import Warning from "./Warning";
import AutoSelect from "./AutoSelect";
import AutoSelectDistributorsCustomer from "./AutoSelectDistributorsCustomers";
import DownloadIcon from "../assets/download";
import ArrowRightIcon from "../assets/arrowRight";
import { ReactComponent as CloseIcon } from "../assets/close.svg";
import ThumbUpIcon from "../assets/thumbUp";
import ThumbDownIcon from "../assets/thumbDown";
import MicrophoneIcon from "../assets/mic";
import Alert from "./Alert";
import { useUIText } from "../hooks";
import Faqs from "./Faqs";
import AutoResizeTextarea from "./TextArea";
import SwitchCustomer from "./SwitchCustomer";
import Logo from "../assets/logo-ver2.svg";
import { useLocation } from "react-router-dom";
import AutoSelectManufacturerAdvCustomer from "./AutoSelectManufacturerAdvCustomer";
import { sendQuestionData, sendUserFeedback } from "../utils";
import ExampleQuestions from "./ExampleQuestions";
import SAicon from "../assets/sa-icon";
import { Tooltip } from "react-tooltip";
import Banner from "./Banner";
import { useIsEmbedded } from "../hooks";
import { AppContext } from "../context";
import Select from "react-dropdown-select";
const languageCodeMap = {
  English: "en-US",
  German: "de-DE",
  French: "fr-FR",
  Polish: "pl-PL",
  Spanish: "es-ES",
};

const FormContainer = ({
  variables,
  setFeedbackIsAdded,
  logo,
  typeOfDemo,
  handleViewChange,
  url,
  isTesting = false,
  feedbackIsAdded,
  setShowFullAccess,
}) => {
  const uiText = useUIText();
  const { instanceInfo, changeInstanceLanguage } = useContext(AppContext);
  const mainLanguage = instanceInfo.mainLanguage;

  const loc = useLocation();
  const path = loc.pathname;

  const view = useMemo(() => {
    if (["/installer", "/testing/installer"].includes(path)) return "Installer";
    if (["/customer", "/testing/customer"].includes(path)) return "Customer";
    return typeOfDemo;
  }, [path, typeOfDemo]);

  const environment = process.env.REACT_APP_ENV;
  const isEmbedded = useIsEmbedded();

  let options = [
    { value: "English", label: "English" },
    // { value: "Arabic", label: "Arabic" },
    { value: "Bulgarian", label: "Bulgarian" },
    { value: "Chinese", label: "Chinese" },
    { value: "Czech", label: "Czech" },
    { value: "Danish", label: "Danish" },
    { value: "Dutch", label: "Dutch" },
    { value: "Estonian", label: "Estonian" },
    { value: "Finnish", label: "Finnish" },
    { value: "French", label: "French" },
    { value: "German", label: "German" },
    { value: "Greek", label: "Greek" },
    { value: "Hungarian", label: "Hungarian" },
    { value: "Indonesian", label: "Indonesian" },
    { value: "Italian", label: "Italian" },
    { value: "Japanese", label: "Japanese" },
    { value: "Korean", label: "Korean" },
    { value: "Latvian", label: "Latvian" },
    { value: "Lithuanian", label: "Lithuanian" },
    { value: "Norwegian", label: "Norwegian" },
    { value: "Polish", label: "Polish" },
    { value: "Portuguese", label: "Portuguese" },
    { value: "portuguese-brazilian", label: "Portuguese (Brazilian)" },
    { value: "Romanian", label: "Romanian" },
    { value: "Russian", label: "Russian" },
    { value: "Slovak", label: "Slovak" },
    { value: "Slovenian", label: "Slovenian" },
    { value: "Spanish", label: "Spanish" },
    { value: "Swedish", label: "Swedish" },
    { value: "Turkish", label: "Turkish" },
    { value: "Ukrainian", label: "Ukrainian" },
  ];

  // Check if the default language is already in the options
  let defaultOptionIndex = options.findIndex(
    (option) => option.value === mainLanguage
  );

  if (defaultOptionIndex !== -1) {
    // If it is, modify its label to include '(default)'
    options[defaultOptionIndex].label = `${options[defaultOptionIndex].label}`;
    // Move the default option to the top of the array
    options.unshift(options.splice(defaultOptionIndex, 1)[0]);
  } else {
    // If it's not, add it to the options at the top
    options.unshift({
      value: mainLanguage,
      label: `${mainLanguage}`,
    });
  }
  const [reset, setReset] = useState(false);
  const [userInput, setUserInput] = useState({
    installer_fallback: true,
    gpt: true,
    company: variables?.slug,
    demo_name: variables?.slug,
    device: "",
    problem: "",
    language: mainLanguage,
    instance_type: "installer",
    config_name: variables.consumerFriendly
      ? "config_customer"
      : "config_default",
    instanceId: process.env.REACT_APP_INSTANCE_ID,
  });

  const [feedbackAdded, setFeedbackAdded] = useState(false);
  const [showThumbs, setShowThumbs] = useState(true);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileError, setFileError] = useState(null);
  const [error, setError] = useState(null);
  const [titleClicked, setTitleClicked] = useState(false);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState(uiText?.status?.processing);
  const [delay, setDelay] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showData, setShowData] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);

  const [showBackButton, setShowBackButton] = useState(false);

  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [transcription, setTranscription] = useState("");
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
  const [backBtnHovered, setBackBtnHovered] = useState(false);
  const [showFeedbackInput, setShowFeedbackInput] = useState(false);
  const [feedbackInput, setFeedbackInput] = useState("");
  const [exampleQuestions, setExampleQuestions] = useState([]);
  const [displayedFaq, setDisplayedFaq] = useState(null);
  const [showFaq, setShowFaq] = useState(true);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [relatedDeviceClicked, setRelatedDeviceClicked] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [welcomeOptionSelected, setWelcomeOptionSelected] = useState(2);
  const [items, setItems] = useState(variables.deviceTypes);
  const [deviceItems, setDeviceItems] = useState(variables.devices);
  const [categoriesContainer, setCategoriesContainer] = useState(
    variables.categoriesContainer
  );

  const faqAvailable = useMemo(() => {
    if (
      variables.faq?.length > 0 &&
      variables.faq.some((faq) => faq.selected)
    ) {
      return true;
    }
    return false;
  }, [variables.faq]);

  const otherQuestionsEnabled = useMemo(() => {
    return (
      variables.otherQuestionsEnabled &&
      variables.otherQuestions?.length > 0 &&
      variables.otherQuestions.some((question) => question.selected)
    );
  }, [variables.otherQuestions, variables.otherQuestionsEnabled]);

  const resetForm = () => {
    setShowData(false);
    setShowQuestion(false);
    setShowProgressBar(false);
    setError(null);
    setFileError(null);
    setData({});
    setFeedbackAdded(false);
    setShowFeedbackInput(false);
    setShowThumbs(true);
    setShowBackButton(false);
    setBackBtnHovered(false);
    setDisplayedFaq(null);
    setUserInput((cur) => ({
      ...cur,
      installer_fallback: true,
      gpt: true,
      company: variables?.slug,
      demo_name: variables?.slug,
      device: "",
      problem: "",
      config_name: variables.consumerFriendly
        ? "config_customer"
        : "config_default",
    }));
    setReset(true);
    setRelatedDeviceClicked(false);
    setRecordId(null);
    setConversationId(null);
    setWelcomeOptionSelected(2);
  };

  useEffect(() => {
    setUserInput((prevUserInput) => ({
      ...prevUserInput,
      language: mainLanguage,
    }));
  }, [mainLanguage]);

  useEffect(() => {
    if (
      userInput.device !== "" &&
      userInput.device !== "general" &&
      variables.type === "Manufacturer"
    ) {
      setExampleQuestions(
        deviceItems.find((device) => device.deviceName === userInput.device)
          .exampleQuestions
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInput.device, deviceItems]);

  const fetchData = async () => {
    const element = document.getElementById("navbar");
    if (element) window.scrollTo(0, element.offsetTop);
    setShowQuestion(true);
    setTimeout(() => {
      setLoading(true);
    }, 10);
    // Define a variable to store the updated device value
    let updatedDevice = userInput.device;

    if (welcomeOptionSelected === 2 && otherQuestionsEnabled) {
      updatedDevice = "general"; // Set device to 'general'
      setUserInput((prevInput) => ({
        ...prevInput,
        device: "general",
      }));
    }
    try {
      if (url) {
        const reachedLimit = await checkLimit();
        if (reachedLimit) {
          setShowFullAccess(true);
          setLoading(false);
          return;
        }
        const res = await axios.post(
          url,
          {
            ...userInput,
            device: updatedDevice,
            logfile: "",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data) {
          setData(res.data);
          console.log(res.data);
          console.log(variables);
          // const element = document.getElementById("results");
          // if (element) window.scrollTo(0, element.offsetTop);
          setFeedbackAdded(false);
          if (!isTesting) {
            const sendQuestionDataResponse = await sendQuestionData(
              userInput,
              (variables.oldAi
                ? res.data.gpt_response
                : res.data.model_response) || "",
              res.data.exact_file || "",
              res.data.page || [],
              null,
              conversationId,
              false,
              res.data.empty_answer,
              otherQuestionsEnabled
            );
            setShowBackButton(!!sendQuestionDataResponse.limitReached ?? false);

            if (sendQuestionDataResponse.recordId) {
              setRecordId(sendQuestionDataResponse.recordId);
            }
            if (sendQuestionDataResponse.conversationId) {
              setConversationId(sendQuestionDataResponse.conversationId);
            }
          }
        } else {
          setData([]);
        }
      }
    } catch (err) {
      console.log(err.message); // Log only the error message
      setError(err.message); // Set only the error message
      if (environment === "production" && !isTesting) {
        handleSendResult(
          false,
          data,
          userInput,
          variables.slug,
          !variables?.oldAi ?? true
        );
      }
      setShowProgressBar(false);
      setShowQuestion(false);
      setProgress(0);
      setStatus("");
      if (!isTesting) {
        sendQuestionData(
          userInput,
          err.message || "error",
          "",
          [],
          null,
          conversationId,
          true,
          true,
          otherQuestionsEnabled
        ).then((response) => {
          if (response.recordId) {
            setRecordId(recordId);
          }
          if (response.conversationId) {
            setConversationId(response.conversationId);
          }
        });
      }
    }
    setLoading(false);
    setTitleClicked(false);
    setFileError(null);
    setProgress(100); // Set progress to 100
    setStatus(uiText?.status?.complete); // Set status to "Complete"
    setTimeout(() => {
      setProgress(0);
      setStatus("");
      setShowProgressBar(false);
      setShowQuestion(false);
      setShowData(true);
    }, 300);
    setRelatedDeviceClicked(false);
  };

  useEffect(() => {
    let interval = null;
    if (loading && !delay) {
      setShowProgressBar(true);
      if (progress === 0) {
        setStatus(uiText?.status?.processing);
      }
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          let newProgress = prevProgress + 1;
          if (newProgress > 100) {
            newProgress = 100; // Ensure progress does not exceed 100
          }
          if (
            newProgress === 5 ||
            newProgress === 25 ||
            newProgress === 50 ||
            newProgress === 75
          ) {
            setStatus(
              newProgress === 5
                ? uiText?.status?.processing
                : newProgress === 25 || newProgress === 50
                ? uiText?.status?.searching
                : uiText?.status?.generating
            );
            clearInterval(interval);
            setDelay(true);
            setTimeout(() => setDelay(false), 500); // Add a delay of half a second
          }
          return newProgress;
        });
      }, 200); // Adjust the time here to control the speed of the progress bar
    } else if (!loading && progress > 1) {
      setProgress(100);
      setStatus(uiText?.status?.complete);
      setTimeout(() => {
        setProgress(0);
        setStatus("");
        setShowProgressBar(false);
        setTimeout(() => setShowData(true), 300); // Delay showing data until after ProgressBar has disappeared
      }, 500);
    }
    return () => clearInterval(interval);
  }, [loading, delay, progress, uiText]);

  const openFile = async (fileName, page, range) => {
    setFileLoading(true);
    try {
      let url = null;
      if (variables.oldAi) {
        url =
          process.env.REACT_APP_ENV === "production"
            ? "https://ai.service-assistant.ai/file/download"
            : "https://ai.service-assistant-staging.com/file/download";
      } else {
        url =
          process.env.REACT_APP_ENV === "production"
            ? "https://new-ai.service-assistant.ai/file/download"
            : "https://new-ai.service-assistant-staging.com/file/download";
      }
      const response = await axios({
        method: "POST",
        url,
        data: {
          filename: `${fileName}`,
          company:
            variables.type === "Manufacturer"
              ? variables.slug
              : String(userInput.company).toLowerCase(),
          instanceId: process.env.REACT_APP_INSTANCE_ID,
        },
        responseType: "arraybuffer",
      });
      if (response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        let url = window.URL.createObjectURL(blob);

        // Convert page and range to an array if they are not already
        // and convert elements to string if they are numbers
        const pageArray = Array.isArray(page)
          ? page.map(String)
          : [String(page)];
        const rangeArray = Array.isArray(range)
          ? range.map(String)
          : [String(range)];

        // Check if the range array is not empty
        if (rangeArray.length > 0 && rangeArray[0]) {
          url += "#page=" + rangeArray[0];
        }
        // If the range array is empty, check if the page array is not empty
        else if (pageArray.length > 0 && pageArray[0]) {
          url += "#page=" + pageArray[0];
        }

        if (window.confirm(uiText?.alerts?.openInNewTab)) {
          window.open(url, "_blank");
        }
      }
    } catch (err) {
      setFileError(err);
      console.log(err);
    }
    setFileLoading(false);
  };

  const handleSubmit = (e) => {
    e && e.preventDefault();
    if (!userInput) return;
    setData({});
    fetchData();
    // setUserInput({ device: "", problem: "", language: "English" });
    setError(null);
    setFileError(null);
    setShowBackButton(false);
  };

  useEffect(() => {
    if (
      Object.keys(data).length > 0 &&
      environment === "production" &&
      !isTesting
    ) {
      handleSendResult(
        true,
        data,
        userInput,
        variables.slug,
        !variables?.oldAi ?? true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); // This effect runs whenever `data` changes

  useEffect(() => {
    if (feedbackAdded) {
      setFeedbackIsAdded(true);
      setTimeout(() => {
        setShowThumbs(false);
        setFeedbackIsAdded(false);
      }, 3500);
    } else {
      setShowThumbs(true);
      setFeedbackIsAdded(false);
    }
  }, [feedbackAdded, setFeedbackIsAdded]);

  useEffect(() => {
    if (titleClicked) {
      handleSubmit();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [titleClicked]);

  // Cleanup function to stop recording and release media resources
  useEffect(() => {
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [mediaRecorder]);

  if (!process.env.REACT_APP_GOOGLE_API_KEY) {
    throw new Error("REACT_APP_GOOGLE_API_KEY not found in the environment");
  }

  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const recorder = new MediaRecorder(stream);
      recorder.start();
      console.log("Recording started");
      setUserInput({
        ...userInput,
        problem: "",
      });

      // Event listener to handle data availability
      recorder.addEventListener("dataavailable", async (event) => {
        console.log("Data available event triggered");
        const audioBlob = event.data;

        const base64Audio = await audioBlobToBase64(audioBlob);
        //console.log('Base64 audio:', base64Audio);
        setIsSpinnerVisible(true);
        try {
          const startTime = performance.now();

          const response = await axios.post(
            `https://speech.googleapis.com/v1/speech:recognize?key=${apiKey}`,
            {
              config: {
                encoding: "WEBM_OPUS",
                sampleRateHertz: 48000,
                languageCode: languageCodeMap[userInput.language] || "en-US",
                enableAutomaticPunctuation: true,
                useEnhanced: true,
                model: "latest_long",
              },
              audio: {
                content: base64Audio,
              },
            }
          );

          const endTime = performance.now();
          const elapsedTime = endTime - startTime;

          //console.log('API response:', response);
          console.log("Time taken (ms):", elapsedTime);

          if (response.data.results && response.data.results.length > 0) {
            setIsSpinnerVisible(false);
            setTranscription(
              response.data.results[0].alternatives[0].transcript
            );
            setUserInput({
              ...userInput,
              problem: response.data.results[0].alternatives[0].transcript,
            });
          } else {
            console.log(
              "No transcription results in the API response:",
              response.data
            );
            setTranscription(uiText?.form?.noTranscription);
          }
          setIsSpinnerVisible(false);
        } catch (error) {
          console.error(
            "Error with Google Speech-to-Text API:",
            error.response.data
          );
        }
      });

      setRecording(true);
      setMediaRecorder(recorder);
    } catch (error) {
      console.error("Error getting user media:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      console.log("Recording stopped");
      setRecording(false);
      // Stop the MediaStream
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
    }
  };

  const hideForm =
    showProgressBar ||
    error ||
    fileError ||
    (showData && Object.keys(data).length > 0) ||
    loading ||
    showQuestion ||
    displayedFaq !== null;

  useEffect(() => {
    if (
      !loading &&
      ((showData && Object.keys(data).length > 0) || error || fileError)
    ) {
      setTimeout(() => {
        setShowBackButton(true);
      }, 1000);
    }
  }, [showData, data, error, fileError, loading]);

  // const handleCheckboxhange = (event) => {
  // 	setUserInput({
  // 		...userInput,
  // 		installer_fallback: event.target.checked,
  // 	});
  // };

  const handleFaqClick = (faq) => {
    setDisplayedFaq(faq);
    setData({});
    setShowBackButton(true);
    setShowFaq(false);
  };
  useEffect(() => {
    if (faqAvailable && exampleQuestions.length === 0) {
      setShowFaq(true);
    } else {
      setShowFaq(false);
    }
    // if (exampleQuestions.length > 0) {
    // 	setShowFaq(false);
    // }
  }, [faqAvailable, exampleQuestions]);

  const faqContainerClass =
    showFaq && faqAvailable && !hideForm
      ? "faq-wrapper visible child-element"
      : "faq-wrapper hidden child-element";
  const exampleQuestionsClass =
    !showFaq && exampleQuestions.length > 0 && !hideForm
      ? "example-questions-container visible"
      : "example-questions-container isHidden child-element";
  const welcomeOptionsClass =
    otherQuestionsEnabled && !hideForm
      ? "faq-wrapper visible child-element"
      : "faq-wrapper hidden child-element";
  const selectWithOtherQuestionsClass =
    otherQuestionsEnabled && !hideForm && welcomeOptionSelected === 1
      ? "select-with-other-questions-up"
      : otherQuestionsEnabled && !hideForm && welcomeOptionSelected === 2
      ? "select-with-other-questions-down isSelectHidden"
      : "";
  const inputWithOtherQuestionsClass =
    otherQuestionsEnabled && !hideForm && welcomeOptionSelected === 1
      ? "input-with-other-questions-down"
      : otherQuestionsEnabled && !hideForm && welcomeOptionSelected === 2
      ? "input-with-other-questions-up"
      : "";

  const handleClickQuestion = (question) => {
    setUserInput({
      ...userInput,
      problem: question,
    });
  };

  const handleCheckboxChange = (event) => {
    setUserInput({
      ...userInput,
      instance_type: event.target.checked ? "customer" : "installer",
    });
  };
  useEffect(() => {
    const translateAndSetQuestions = async () => {
      // Validate inputs
      if (!userInput.language) {
        console.error("Language not specified");
        return;
      }

      const otherQuestions = variables.otherQuestionsEnabled
        ? variables.otherQuestions.map((question) => question.question) || []
        : [];
      if (!otherQuestions || !Array.isArray(otherQuestions)) {
        console.error("Invalid other questions");
        return;
      }

      // Prepare the questionsObj to handle both deviceTypes and devices
      const questionsObj = {
        open: otherQuestions, // Include open questions
        deviceTypes: [], // For deviceTypes (manufacturerName:deviceName)
        devices: [], // For individual devices
        categories: [], // For categoriesContainer
      };

      // If deviceTypes exist, prepare questions for them
      if (variables.deviceTypes && variables.deviceTypes.length > 0) {
        variables.deviceTypes.forEach((type) => {
          type.manufacturers.forEach((manufacturer) => {
            manufacturer.devices.forEach((device) => {
              if (device.exampleQuestions.length > 0) {
                questionsObj.deviceTypes.push({
                  manufacturerName: manufacturer.manufacturer,
                  deviceName: device.deviceName,
                  exampleQuestions: device.exampleQuestions,
                });
              }
            });
          });
        });
      }

      // If deviceTypes are empty but devices are available
      if (variables.deviceTypes.length === 0 && variables.devices.length > 0) {
        variables.devices.forEach((device) => {
          if (device.exampleQuestions.length > 0) {
            questionsObj.devices.push({
              deviceName: device.deviceName,
              exampleQuestions: device.exampleQuestions,
            });
          }
        });
      }
      // If categoriesContainer is available
      if (
        variables.deviceTypes.length === 0 &&
        variables.devices.length === 0
      ) {
        variables.categoriesContainer.categories.forEach((category) => {
          category.devices.forEach((device) => {
            if (device.exampleQuestions && device.exampleQuestions.length > 0) {
              // Collect example questions from the category's devices
              questionsObj.categories.push({
                categoryName: category.name,
                deviceName: device.deviceName,
                exampleQuestions: device.exampleQuestions,
              });
            }
          });
        });
      }

      try {
        const translatedQuestions = await translateQuestions(
          questionsObj,
          userInput.language,
          otherQuestionsEnabled
        );

        if (translatedQuestions && Array.isArray(translatedQuestions)) {
          const deviceQuestionsMap = {};
          translatedQuestions.forEach((item) => {
            if (item.key !== "open") {
              deviceQuestionsMap[item.key] = item.questions;
            }
          });

          // If deviceTypes exist, update the questions accordingly
          if (variables.deviceTypes && variables.deviceTypes.length > 0) {
            const updatedDeviceTypes = variables.deviceTypes.map((type) => ({
              ...type,
              manufacturers: type.manufacturers.map((manufacturer) => ({
                ...manufacturer,
                devices: manufacturer.devices.map((device) => ({
                  ...device,
                  exampleQuestions:
                    deviceQuestionsMap[
                      `${manufacturer.manufacturer}:${device.deviceName}`
                    ] || device.exampleQuestions,
                })),
              })),
            }));
            setItems(updatedDeviceTypes); // Update state for deviceTypes
          }

          // If only devices exist, update the devices accordingly
          if (
            variables.deviceTypes.length === 0 &&
            variables.devices.length > 0
          ) {
            const updatedDevices = variables.devices.map((device) => ({
              ...device,
              exampleQuestions:
                deviceQuestionsMap[device.deviceName] ||
                device.exampleQuestions,
            }));
            setDeviceItems(updatedDevices); // Update state for devices
          }

          // If categoriesContainer exists, update the categories accordingly
          if (
            variables.deviceTypes.length === 0 &&
            variables.devices.length === 0
          ) {
            const updatedCategoriesContainer = {
              ...variables.categoriesContainer,
              categories: variables.categoriesContainer.categories.map(
                (category) => ({
                  ...category,
                  devices: category.devices.map((device) => ({
                    ...device,
                    exampleQuestions:
                      deviceQuestionsMap[
                        `${category.name}:${device.deviceName}`
                      ] || device.exampleQuestions,
                  })),
                })
              ),
            };

            // Update the state for categoriesContainer
            setCategoriesContainer(updatedCategoriesContainer);
          }
        } else {
          console.error("Invalid translation result");
        }
      } catch (error) {
        console.error("Error translating questions:", error);
      }
    };

    translateAndSetQuestions();
  }, [userInput.language]);

  const ProtectionSystem = () => {
    return (
      <div
        id="protection-tooltip"
        data-tooltip-id="protectionTooltip"
        style={{ display: "flex" }}
      >
        <label
          className="options-checkbox"
          style={{
            color:
              userInput.instance_type === "customer"
                ? variables.mainColor
                : "#90A0B7",
            display: "flex",
            marginRight:
              typeOfDemo === "Both" || typeOfDemo === "Customer" ? "19px" : "0",
            marginTop: "3px",
            lineHeight: "normal",
          }}
        >
          <p>{uiText?.result?.protectionSystem}</p>
          <input
            name="origin"
            type="checkbox"
            value={userInput.instance_type}
            checked={userInput.instance_type === "customer"}
            onChange={handleCheckboxChange}
            disabled={loading}
          />
          <span
            className="checkmark"
            style={{
              borderColor:
                userInput.instance_type === "customer"
                  ? variables.mainColor
                  : "#42536D",
              backgroundColor:
                userInput.instance_type === "customer"
                  ? variables.mainColor
                  : "transparent",
            }}
          ></span>
        </label>
        <Tooltip
          id="protectionTooltip"
          className="protection-tooltip"
          place="left"
          effect="solid"
          positionStrategy="fixed"
          // afterShow={adjustTooltipPosition}
        >
          <div>{uiText?.form?.protectionTooltip}</div>
        </Tooltip>
      </div>
    );
  };

  const Languages = () => {
    return (
      <div
        className={
          typeOfDemo === "Both"
            ? "language-select"
            : "language-select no-switch"
        }
        style={{ marginRight: typeOfDemo === "Customer" ? 0 : "9px" }}
      >
        <Select
          options={options}
          values={options.filter(
            (option) => option.value === userInput.language
          )}
          searchable="false"
          className="select card language"
          onChange={(selectedOption) => {
            setUserInput({
              ...userInput,
              language: selectedOption[0].value,
            });
            
            changeInstanceLanguage(selectedOption[0].value);
            setReset(true);
          }}
        />
      </div>
    );
  };

  const onBackButtonClick = () => {
    setShowData(false);
    setShowQuestion(false);
    setShowProgressBar(false);
    setError(null);
    setFileError(null);
    setData({});
    setFeedbackAdded(false);
    setShowFeedbackInput(false);
    setShowThumbs(true);
    setShowBackButton(false);
    setBackBtnHovered(false);
    setDisplayedFaq(null);
    if (faqAvailable && exampleQuestions.length === 0) {
      setShowFaq(true);
    }
    setUserInput({
      ...userInput,
      problem: "",
    });
    setRelatedDeviceClicked(false);
  };
  return (
    <div
      className={
        faqAvailable && showFaq
          ? "customer-container customer-container--faq"
          : "customer-container"
      }
    >
      {!isEmbedded ? (
        <div className={optionsOpen ? "modal visible" : "modal"}>
          <SwitchCustomer
            view={view}
            handleViewChange={handleViewChange}
            children={<ProtectionSystem />}
            showSwitch={typeOfDemo === "Both"}
            optionsOpen={optionsOpen}
            setOptionsOpen={setOptionsOpen}
            mainColor={variables.mainColor}
            secondChildren={<Languages />}
          />
        </div>
      ) : null}
      {variables?.hideHeader ? null : (
        <div className={variables.active ? "navbar with-border" : "navbar"}>
          <div className="navbar-logo">
            <div className="nav-left">
              <img
                src={logo || Logo}
                alt="Logo"
                onClick={resetForm}
                style={{ cursor: "pointer" }}
              />
              {showBackButton && (
                <div className="back-btn">
                  <button
                    onClick={() => {
                      onBackButtonClick();
                    }}
                    onMouseEnter={() => setBackBtnHovered(true)}
                    onMouseLeave={() => setBackBtnHovered(false)}
                    style={{
                      borderColor: "transparent",
                      backgroundColor: "transparent",
                      color: backBtnHovered ? variables.mainColor : "#6982A8",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <span>+ {uiText?.askNewQuestion}</span>
                  </button>
                </div>
              )}
            </div>
            {!isEmbedded ? (
              <SwitchCustomer
                view={view}
                handleViewChange={handleViewChange}
                children={<ProtectionSystem />}
                optionsOpen={optionsOpen}
                showSwitch={typeOfDemo === "Both"}
                setOptionsOpen={setOptionsOpen}
                mainColor={variables.mainColor}
                secondChildren={<Languages />}
              />
            ) : null}
          </div>
          {variables.active ? null : (
            <div className="baner-container">
              <Banner feedbackIsAdded={feedbackIsAdded} />{" "}
            </div>
          )}
        </div>
      )}
      <div
        className="customer-wrapper"
        style={{ marginTop: variables?.hideHeader ? "0" : "75px" }}
      >
        {showBackButton && variables?.hideHeader && (
          <div className="back-btn no-header">
            <button
              onClick={() => {
                onBackButtonClick();
              }}
              onMouseEnter={() => setBackBtnHovered(true)}
              onMouseLeave={() => setBackBtnHovered(false)}
              style={{
                borderColor: "transparent",
                backgroundColor: "transparent",
                color: backBtnHovered ? variables.mainColor : "#6982A8",
                whiteSpace: "nowrap",
              }}
            >
              <span>+ {uiText?.askNewQuestion}</span>
            </button>
          </div>
        )}
        <div
          className="customer"
          style={{ marginTop: variables.hideHeader ? "32px" : "0px" }}
        >
          <div className={fileLoading ? "modal visible" : "modal"}>
            <div className="modal-content">
              <span>{uiText?.form?.loadingFile}</span>
            </div>
          </div>
          <div className={`form-page ${showFaq ? "space-between" : "center"}`}>
            {!hideForm && (
              <div className={`avatar-container ${hideForm ? "hide" : ""} `}>
                {/* <div className="avatar-logo">
									</div> */}
                <div className="avatar-text">
                  {error ||
                  showProgressBar ||
                  (showData && Object.keys(data).length > 0) ||
                  displayedFaq !== null ? null : (
                    <div className="avatar-info">{uiText.form.greetings}</div>
                  )}
                </div>
              </div>
            )}
            {faqAvailable && (
              <div className={faqContainerClass}>
                <Faqs
                  faqs={variables.faq}
                  borderColor={variables.mainColor}
                  displayedFaq={displayedFaq}
                  handleFaqClick={handleFaqClick}
                />
              </div>
            )}
            <div className={`form-container ${hideForm ? "hide" : ""}`}>
              <form
                onSubmit={handleSubmit}
                className={`form ${hideForm ? "hide" : ""}`}
                style={{
                  height: faqAvailable && showFaq ? "100%" : "inherit",
                }}
              >
                <div
                  className={
                    showFaq ? "form-group" : "form-group form-group--full"
                  }
                  style={{
                    marginTop: faqAvailable ? "0px" : "0",
                    justifyContent: "flex-end",
                    flexGrow: faqAvailable ? 1 : 0,
                    height: faqAvailable && showFaq ? "100%" : "auto",
                  }}
                >
                  {variables.type === "Manufacturer" ? (
                    <div className="manufacturer-selection">
                      {" "}
                      {/* <label htmlFor="device">{`${uiText?.form?.device} *`}</label> */}
                      <div className="selection-title">
                        {!userInput.device
                          ? uiText.form.selectDevice
                          : uiText.form.yourDevice}
                      </div>
                      <div className="selection-container">
                        <div className="customer-selection">
                          <AutoSelect
                            items={deviceItems}
                            setSelectedDevice={(device) =>
                              setUserInput({
                                ...userInput,
                                device,
                                problem: "",
                              })
                            }
                            device={userInput.device}
                            menuPlacement={"top"}
                          />
                        </div>
                      </div>
                    </div>
                  ) : variables.type === "Distributor" ? (
                    <AutoSelectDistributorsCustomer
                      items={items}
                      userInput={userInput}
                      setUserInput={setUserInput}
                      setExampleQuestions={setExampleQuestions}
                      reset={reset}
                      setReset={setReset}
                      handleSetShowFaqs={setShowFaq}
                      setRelatedDevices={() => null}
                      relatedDeviceClicked={relatedDeviceClicked}
                      setRelatedDeviceClicked={setRelatedDeviceClicked}
                    />
                  ) : (
                    <AutoSelectManufacturerAdvCustomer
                      items={categoriesContainer}
                      userInput={userInput}
                      setUserInput={setUserInput}
                      setExampleQuestions={setExampleQuestions}
                      reset={reset}
                      setReset={setReset}
                      handleSetShowFaqs={setShowFaq}
                      setRelatedDevices={() => null}
                    />
                  )}
                  {/* <label htmlFor="email">Email</label>
							<input
								type="text"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
								placeholder={uiText?.form?.emailPlaceholder}
								className="email card"
							/> */}
                  {/* <label htmlFor="problem">{uiText?.form?.question} *</label> */}
                  <div
                    className="input-container flex"
                    style={{
                      width: "100%",
                      maxWidth: " 849px",
                      marginInline: "auto",
                    }}
                  >
                    <AutoResizeTextarea
                      // rows={8}
                      placeholder={
                        recording || isSpinnerVisible
                          ? ""
                          : transcription === uiText?.form?.noTranscript
                          ? uiText?.form?.noTranscriptMessage
                          : uiText?.form?.questionPlaceholder
                      }
                      value={userInput.problem || ""}
                      onChange={(e) =>
                        setUserInput({ ...userInput, problem: e.target.value })
                      }
                      color={variables.mainColor}
                    />
                    {/* <div className="microphone-background"> */}

                    {!recording && (
                      <div className="right-side">
                        <div
                          className={
                            isSpinnerVisible
                              ? "microphone disabled"
                              : "microphone"
                          }
                          onClick={isSpinnerVisible ? null : startRecording}
                          style={{ backgroundColor: "white" }}
                        >
                          <MicrophoneIcon color="#B3CADD" />
                        </div>
                        <button
                          type="submit"
                          className={
                            userInput.device === "" ||
                            userInput.problem === "" ||
                            !variables?.aiTrained
                              ? "submit-btn disabled"
                              : "submit-btn"
                          }
                          disabled={
                            userInput.device === "" ||
                            userInput.problem === "" ||
                            !variables?.aiTrained
                          }
                          style={{
                            backgroundColor: variables.mainColor,
                            color:
                              getBrightness(variables.mainColor) > 155
                                ? "black"
                                : "white",
                            border: `1px solid ${variables.mainColor}`,
                            width: "32px",
                            height: "32px",
                            padding: "8px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ArrowRightIcon
                            color={
                              getBrightness(variables.mainColor) > 155
                                ? "#000000"
                                : "#ffffff"
                            }
                          />
                        </button>
                      </div>
                    )}

                    {/* </div> */}
                  </div>
                  {isSpinnerVisible && (
                    <div
                      style={{
                        position: "relative",
                        marginInline: "auto",
                        top: "16px",
                      }}
                    >
                      <div className="spnr"></div>
                    </div>
                  )}
                  {recording && (
                    <div style={{ position: "relative", marginInline: "auto" }}>
                      <VoiceAnimation />
                      <div className="stop-recording__container">
                        <div
                          className="stop-recording__background"
                          style={{ backgroundColor: variables.mainColor }}
                        ></div>
                        <div
                          className="stop-recording"
                          onClick={stopRecording}
                          style={{ backgroundColor: variables.mainColor }}
                        >
                          <span
                            style={{
                              borderColor:
                                getBrightness(variables.mainColor) > 155
                                  ? "#000000"
                                  : "#ffffff",
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  )}
                  {!showFaq ? (
                    <div className={exampleQuestionsClass}>
                      {exampleQuestions.length > 0 ? (
                        <div
                          className="selection-title"
                          style={{ textAlign: "left", paddingTop: "20px" }}
                        >
                          {uiText?.form?.exampleQuestions}:
                        </div>
                      ) : null}
                      <ExampleQuestions
                        exampleQuestions={exampleQuestions}
                        borderColor={variables.mainColor}
                        cursor={
                          isSpinnerVisible || recording
                            ? "not-allowed"
                            : "pointer"
                        }
                        onClick={
                          isSpinnerVisible || recording || loading
                            ? null
                            : handleClickQuestion
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
            <div
              className={
                showProgressBar ||
                error ||
                fileError ||
                (showData && Object.keys(data).length > 0) ||
                displayedFaq !== null
                  ? "feedback-container show"
                  : "feedback-container"
              }
              id="results"
            >
              {/* <div className="ai-avatar results">
						<ResultsIcon
							circleColor={variables.mainColor}
							color={
								getBrightness(variables.mainColor) > 155 ? "black" : "white"
							}
						/>
						<span>Results</span>
						<label className="options-checkbox desktop">
							Protection System
							<input
								name="origin"
								type="checkbox"
								value={userInput.installer_fallback}
								checked={userInput.installer_fallback}
								onChange={handleCheckboxhange}
							/>
							<span className="checkmark"></span>
						</label>
					</div> */}
              {(showProgressBar ||
                (showData && Object.keys(data).length > 0)) && (
                <div className="customer-question">
                  <div className="customer-question__top">{uiText?.you}:</div>
                  <div className="customer-question__middle">
                    {userInput.problem}
                  </div>
                  <div className="customer-question__bottom">
                    {formatDeviceName(userInput.device)}
                  </div>
                </div>
              )}
              <div
                className={
                  !Object.keys(data).length > 0 ||
                  showProgressBar ||
                  displayedFaq !== null
                    ? "feedback card"
                    : "feedback card hide"
                }
                // style={{
                // 	padding:
                // 		!Object.keys(data).length > 0 || showProgressBar
                // 			? "1.5rem"
                // 			: "0",
                // }}
              >
                {faqAvailable && displayedFaq !== null && (
                  <div className="faq-answer__big">
                    <Faqs
                      faqs={variables.faq}
                      borderColor={variables.mainColor}
                      displayedFaq={displayedFaq}
                      handleFaqClick={handleFaqClick}
                    />
                  </div>
                )}
                {error && (
                  <NoResults
                    txt={variables.customFallbackMessage ?? ""}
                    protectionSystem={false}
                  />
                )}
                {fileError && (
                  <Alert
                    message={uiText?.alerts?.fileOpenError}
                    duration={5000} // Duration in milliseconds
                    close={() => setFileError(null)}
                  />
                )}
                {showProgressBar && (
                  <div className="progress-desktop">
                    <ProgressBar
                      progress={progress}
                      status={status}
                      color={variables.mainColor}
                    />
                  </div>
                )}

                {showData && Object.keys(data).length > 0 ? (
                  <>
                    {/* {data?.exact_file
											? (() => {
													return (
														<div className="feedback-top">
															<div className="user-question">
																{userInput.problem}
															</div>
														</div>
													);
											  })()
											: null} */}

                    <div className="feedback-instructions">
                      {(variables.oldAi
                        ? data?.gpt_response
                        : data?.model_response) && !data?.empty_answer ? (
                        <>
                          <div className="feedback-documents__col">
                            <div className="assistant-avatar">
                              {variables?.avatarImg ? (
                                <img
                                  src={variables.avatarImg}
                                  alt="avatar-img"
                                  style={{
                                    maxWidth: "40px",
                                    maxHeight: "40px",
                                  }}
                                />
                              ) : (
                                <SAicon
                                  circleColor={variables.mainColor}
                                  color={
                                    getBrightness(variables.mainColor) > 155
                                      ? "black"
                                      : "white"
                                  }
                                />
                              )}

                              <span>
                                {variables.avatarName ?? "Service Assistant AI"}
                              </span>
                            </div>
                            {typeof data.warning === "string" &&
                            data.warning.length > 0 ? (
                              <Warning warningText={data.warning} />
                            ) : null}
                            <div
                              className="feedback-header"
                              style={{ marginTop: "1rem" }}
                            >
                              <span>{uiText?.result?.instructions}:</span>
                            </div>
                            <div className="feedback-instruction">
                              <StepsComponent
                                inputText={
                                  variables.oldAi
                                    ? data.gpt_response
                                    : data.model_response
                                }
                                language={userInput.language}
                                markerColor={variables.mainColor}
                                markerTextColor={getBrightness(variables.mainColor) > 155 ? "black" : "white"}
                              />
                            </div>
                          </div>
                        </>
                      ) : (variables.oldAi
                          ? data?.gpt_response
                          : data?.model_response) && data.empty_answer ? (
                        <NoResults
                          txt={variables.customFallbackMessage ?? ""}
                          protectionSystem={variables.consumerFriendly ?? false}
                        />
                      ) : null}
                      <div
                        className={
                          data?.exact_file
                            ? "feedback-documents"
                            : "feedback-documents none"
                        }
                        style={{ padding: "0px" }}
                      >
                        {data?.exact_file && !data.empty_answer
                          ? (() => {
                              let fileName;
                              if (Array.isArray(data.exact_file)) {
                                const fileObj = data.exact_file[0]; // Get the first object from the array
                                fileName = Object.keys(fileObj)[0]; // Get the key of the object which is the file name
                              } else {
                                fileName = data.exact_file;
                              }

                              // Convert page to an array if it's not already
                              const pageArray = Array.isArray(data.page)
                                ? data.page
                                : [data.page];
                              const pageText = pageArray.join(", ");
                              const isMultiplePages = pageArray.length > 1;

                              return (
                                <div
                                  className="feedback-documents__col border-top"
                                  style={{ padding: "1rem 0 0" }}
                                >
                                  <div className="feedback-file customer__feedback-file">
                                    <div
                                      className="file-title"
                                      onClick={() =>
                                        openFile(
                                          fileName,
                                          data.page || [],
                                          data.range || []
                                        )
                                      }
                                      style={{ color: variables.mainColor }}
                                    >
                                      <DownloadIcon
                                        color={variables.mainColor}
                                        style={{ marginRight: "6px" }}
                                      />
                                      <div>
                                        <div>
                                          <span
                                            style={{
                                              color: variables.mainColor,
                                            }}
                                          >
                                            {uiText?.result?.findMoreInfo}{" "}
                                          </span>
                                          {pageText?.length ? (
                                            <span
                                              style={{
                                                color: variables.mainColor,
                                              }}
                                            >
                                              {isMultiplePages
                                                ? uiText?.result?.pages
                                                : uiText?.result?.page}{" "}
                                              {pageText}
                                            </span>
                                          ) : null}
                                        </div>
                                        <div className="file-title__small-text">
                                          {uiText?.result?.downloadFile}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })()
                          : null}
                        {/* {relatedDevices.length > 0 && !data.empty_answer ? (
													<div className="feedback-documents__col border-top">
														<div className="feedback-header">
															<span>
																{uiText?.result?.tabs?.instructions
																	?.relatedDevices + ":"}
															</span>
															{relatedDevices.map((device, index) => (
																<div
																	key={index}
																	className="related-device"
																	style={{ color: variables.mainColor }}
																	onClick={() =>
																		handleRelatedDeviceClick(
																			device,
																			userInput.problem
																		)
																	}
																>
																	{`${formatDeviceName(device)}${
																		index === relatedDevices.length - 1
																			? ""
																			: ","
																	}`}
																</div>
															))}
														</div>
													</div>
												) : null} */}
                      </div>
                    </div>
                    {Object.keys(data).length > 0 &&
                      data?.exact_file === null &&
                      !data.empty_answer && (
                        <NoResults
                          txt={variables.customFallbackMessage ?? ""}
                          protectionSystem={variables.consumerFriendly ?? false}
                        />
                      )}
                  </>
                ) : null}
              </div>
              <div className="faq-mobile">
                {faqAvailable && displayedFaq !== null && (
                  <div className="faq-answer__big">
                    <Faqs
                      faqs={variables.faq}
                      borderColor={variables.mainColor}
                      displayedFaq={displayedFaq}
                      handleFaqClick={handleFaqClick}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* {showProgressBar && (
							<div className="progress-mobile">
								<ProgressBar
									progress={progress}
									status={status}
									color={variables.mainColor}
								/>
							</div>
						)} */}
          </div>
          <div className="error-mobile">
            {error && <NoResults txt={variables.customFallbackMessage ?? ""} />}
            {fileError && (
              <Alert
                message={uiText?.alerts?.fileOpenError}
                duration={5000} // Duration in milliseconds
                close={() => setFileError(null)}
              />
            )}
          </div>
          {(showBackButton && showThumbs && data?.exact_file) ||
          (showThumbs && displayedFaq !== null) ? (
            <div
              className={
                showFeedbackInput ? "user-feedback show-input" : "user-feedback"
              }
            >
              {feedbackAdded ? (
                <div className="user-feedback__text">
                  {uiText?.result?.feedback.thankYou}
                </div>
              ) : showFeedbackInput ? (
                <div className="user-feedback__input">
                  <div
                    className="close-button__feedback "
                    onClick={() => setShowFeedbackInput(false)}
                  >
                    <CloseIcon />
                  </div>
                  <div className="feedback-text">
                    {uiText?.result?.feedback.description}
                  </div>
                  <textarea
                    value={feedbackInput}
                    onChange={(e) => setFeedbackInput(e.target.value)}
                    placeholder={uiText?.result?.feedback.inputPlaceholder}
                    type="text"
                    id="feedback-input"
                    name="feedback-input"
                    className="card"
                  />
                  <button
                    type="submit"
                    className="submit-btn"
                    style={{
                      backgroundColor: variables.mainColor,
                      color:
                        getBrightness(variables.mainColor) > 155
                          ? "black"
                          : "white",
                      border: `1px solid ${variables.mainColor}`,
                      marginTop: "12px",
                    }}
                    onClick={() => {
                      if (environment === "production") {
                        handleFeedbackSubmit(
                          "Thumb Down",
                          data,
                          userInput,
                          setFeedbackAdded,
                          variables.slug,
                          feedbackInput,
                          setShowFeedbackInput
                        );
                      } else {
                        setFeedbackAdded(true);
                        setShowFeedbackInput(false);
                      }
                      if (displayedFaq === null) {
                        sendUserFeedback(null, false, conversationId);
                      }
                    }}
                  >
                    <div className="thumb-button">
                      <ThumbDownIcon />
                      {uiText?.result?.feedback.submit}
                    </div>
                  </button>
                </div>
              ) : (
                <>
                  <div className="user-feedback__text">
                    {uiText?.result?.feedback.title}
                  </div>
                  <div className="user-feedback__buttons">
                    <div
                      className="user-feedback__button"
                      onClick={() => setShowFeedbackInput(true)}
                    >
                      <ThumbDownIcon />
                    </div>
                    <div
                      className="user-feedback__button"
                      onClick={() => {
                        if (environment === "production") {
                          handleFeedbackSubmit(
                            "Thumb Up",
                            data,
                            userInput,
                            setFeedbackAdded,
                            variables.slug
                          );
                        } else {
                          setFeedbackAdded(true);
                        }
                        if (displayedFaq === null) {
                          sendUserFeedback(null, true, conversationId);
                        }
                      }}
                    >
                      <ThumbUpIcon />
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default FormContainer;
