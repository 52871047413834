import axios from "axios";

import byd from "../assets/manufacturers/byd.png";
import clemap from "../assets/manufacturers/clemap.png";
import daikin from "../assets/manufacturers/daikin.png";
import delta from "../assets/manufacturers/delta.png";
import fronius from "../assets/manufacturers/fronius.png";
import goodwee from "../assets/manufacturers/goodwee.png";
import enphase from "../assets/manufacturers/enphase.png";
import huawei from "../assets/manufacturers/huawei.png";
import sma from "../assets/manufacturers/sma.png";
import solis from "../assets/manufacturers/solis.png";
import sungrow from "../assets/manufacturers/sungrow.png";
import jasolar from "../assets/manufacturers/jasolar.png";
import jinko from "../assets/manufacturers/jinko.png";
import varta from "../assets/manufacturers/varta.png";
import solaredge from "../assets/manufacturers/solaredge.png";
import longi from "../assets/manufacturers/longi.png";
import sunsynk from "../assets/manufacturers/sunsynk.png";
import socomec from "../assets/manufacturers/socomec.png";
import tigo from "../assets/manufacturers/tigo.svg";
import foxess from "../assets/manufacturers/foxess.png";
import hypontech from "../assets/manufacturers/hypontech.jpg";
import sofarsolar from "../assets/manufacturers/sofarsolar.png";
import mitsubishi from "../assets/manufacturers/mitsubishi.png";
import solaxpower from "../assets/manufacturers/solaxpower.png";
import ecoflow from "../assets/manufacturers/ecoflow.png";
import easee from "../assets/manufacturers/easee.png";
import sonnenkraft from "../assets/manufacturers/sonnenkraft.png";
import aegsolar from "../assets/manufacturers/agesolar.png";
import duracell from "../assets/manufacturers/duracell.png";
import greensolar from "../assets/manufacturers/greensolar.png";
import anker from "../assets/manufacturers/anker.png";
import emaldo from "../assets/manufacturers/emaldo.png";
import hoymiles from "../assets/manufacturers/hoymiles.png";
import sigenergy from "../assets/manufacturers/sigenergy.png";
import hanchuess from "../assets/manufacturers/hanchuess.png";
import luxpower from "../assets/manufacturers/luxpower.png";
import qcells from "../assets/manufacturers/qcells.png";
import myenergi from "../assets/manufacturers/myenergi.png";
import pylontech from "../assets/manufacturers/pylontech.jpeg";
import smappee from "../assets/manufacturers/smappee.png";
import tesla from "../assets/manufacturers/tesla.png";
import thermics from "../assets/manufacturers/thermics.png";
import PencilIcon from "../assets/pencil.js";
import StarIcon from "../assets/star.js";
import LightbulbIcon from "../assets/lightbulb.js";
import CompassIcon from "../assets/compass.js";
import QuestionIcon from "../assets/question.js";
import abb from "../assets/manufacturers/abb.png";
import blink from "../assets/manufacturers/blink.jpg";
import chargetronix from "../assets/manufacturers/chargetronix.png";
import truckroll from "../assets/manufacturers/truckroll.png";
import saj from "../assets/manufacturers/saj.webp";
import esdec from "../assets/manufacturers/esdec.jpg";
const manufacturerLogos = {
  abb,
  esdec,
  saj,
  blink,
  chargetronix,
  truckroll,
  byd,
  tesla,
  thermics,
  myenergi,
  pylontech,
  smappee,
  clemap,
  luxpower,
  hanchuess,
  qcells,
  daikin,
  mitsubishi,
  delta,
  fronius,
  goodwe: goodwee,
  enphase,
  huawei,
  sma,
  solis,
  sungrow,
  jasolar,
  jinko,
  varta,
  solaredge,
  longi,
  sunsynk,
  socomec,
  tigo,
  foxess,
  hypontech,
  sofarsolar,
  solaxpower,
  ecoflow,
  easee,
  sonnenkraft,
  aegsolar,
  duracell,
  greensolar,
  anker,
  emaldo,
  hoymiles,
  sigenergy,
};

export function getBrightness(color) {
  const rgb = parseInt(color.replace("#", ""), 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  // Calculate brightness according to the formula: (299*R + 587*G + 114*B) / 1000
  return (299 * r + 587 * g + 114 * b) / 1000;
}

// Function to convert audio blob to base64 encoded string
export function audioBlobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const arrayBuffer = reader.result;
      const base64Audio = btoa(
        new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      resolve(base64Audio);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
}

// Helper function to replace underscores with spaces in labels
export function formatDeviceName(name) {
  return name.replace(/_/g, " ");
}

function unifyName(str) {
  return str.split(" ").join("");
}
export function manufacturerLogo(manufacturer) {
  return manufacturerLogos[unifyName(manufacturer).toLowerCase()];
}

export const sendQuestionData = async (
  userInput,
  answer,
  sourceDocument = "",
  sourcePages = [],
  sessionId = null,
  conversationId = null,
  error = false,
  emptyAnswer = true,
  otherQuestionsEnabled = false
) => {
  const instanceId = process.env.REACT_APP_INSTANCE_ID;
  const environment = process.env.REACT_APP_ENV;
  let logUrl = null;

  switch (environment) {
    case "staging":
      logUrl =
        "https://admin-api.service-assistant-staging.com/instance/record-question";
      break;
    case "production":
      logUrl =
        "https://admin-api.service-assistant.ai/instance/record-question";
      break;
    default:
      logUrl = "http://localhost:9000/instance/record-question";
      break;
  }
  if (logUrl) {
    const postData = {
      instanceId: instanceId,
      deviceName: otherQuestionsEnabled ? "general" : userInput.device,
      deviceType: otherQuestionsEnabled ? "general" : userInput.deviceType,
      manufacturer: otherQuestionsEnabled ? "general" : userInput.manufacturer,
      question: userInput.problem,
      answer: answer,
      sourceDocument,
      sourcePages: sourcePages.join(","),
      error: error,
      emptyAnswer: emptyAnswer,
    };

    if (conversationId) {
      postData.conversationId = conversationId;
    }
    if (sessionId) {
      postData.sessionId = sessionId;
    }
    try {
      const res = await axios.post(logUrl, postData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return {
        recordId: res.data.recordId,
        conversationId: res.data.conversationId,
      };
    } catch (err) {
      console.error("Failed to log question data:", err.message);
      if (err.status === 403) {
        return {
          limitReached: true,
        };
      } else {
        return null;
      }
    }
  }
  return null;
};

export const checkLimit = async () => {
  const instanceId = process.env.REACT_APP_INSTANCE_ID;
  const environment = process.env.REACT_APP_ENV;
  let url = null;

  switch (environment) {
    case "staging":
      url = `https://admin-api.service-assistant-staging.com/instance/${instanceId}/check-limit`;
      break;
    case "production":
      url = `https://admin-api.service-assistant.ai/instance/${instanceId}/check-limit`;
      break;
    default:
      url = `http://localhost:9000/instance/${instanceId}/check-limit`;
      break;
  }
  if (url) {
    const postData = {
      instanceId: instanceId,
    };
    try {
      const res = await axios.get(url, postData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res.data.limitReached;
    } catch (err) {
      console.error("Failed to check limit:", err.message);
      return false;
    }
  }
  return false;
};

export const sendUserFeedback = async (recordId, positive, conversationId) => {
  const environment = process.env.REACT_APP_ENV;
  let logUrl = null;

  switch (environment) {
    case "staging":
      logUrl =
        "https://admin-api.service-assistant-staging.com/instance/mark-record";
      break;
    case "production":
      logUrl = "https://admin-api.service-assistant.ai/instance/mark-record";
      break;

    default:
      logUrl = "http://localhost:9000/instance/mark-record";
      break;
  }
  if (logUrl) {
    const postData = {
      positive: positive,
    };
    if (recordId) {
      postData.recordId = recordId;
    }
    if (conversationId) {
      postData.conversationId = conversationId;
    }
    try {
      await axios.post(logUrl, postData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      console.error("Failed to log user feedback:", err.message);
    }
  }
};

export const randomId = () => {
  return String(Date.now().toString(32) + Math.random().toString(16)).replace(
    /\./g,
    ""
  );
};

export const renderIcon = (iconName, color, strokeWidth = 2) => {
  switch (iconName) {
    case "PENCIL":
      return <PencilIcon color={color} strokeWidth={strokeWidth} />;
    case "STAR":
      return <StarIcon color={color} strokeWidth={strokeWidth} />;
    case "LIGHTBULB":
      return <LightbulbIcon color={color} strokeWidth={strokeWidth} />;
    case "COMPASS":
      return <CompassIcon color={color} strokeWidth={strokeWidth} />;
    case "QUESTION":
      return <QuestionIcon color={color} strokeWidth={strokeWidth} />;
    default:
      return null; // Or return a default icon or nothing
  }
};

export const translateQuestions = async (
	questionsObj,
	language,
	openQuestionsEnabled
) => {
	const url =
		process.env.REACT_APP_ENV === "production"
			? "https://admin-api.service-assistant.ai/translate"
			: "https://admin-api.service-assistant-staging.com/translate";

	// Build the request body dynamically based on provided question sections
	const requestBody = {
		questions: [],
		language: language,
	};

	// Add 'open' section if it exists
	if (questionsObj.open && openQuestionsEnabled) {
		requestBody.questions.push({
			key: "open",
			questions: questionsObj.open,
		});
	}

	// Handle deviceTypes section (if exists)
	if (questionsObj.deviceTypes && questionsObj.deviceTypes.length > 0) {
		questionsObj.deviceTypes.forEach(
			({ manufacturerName, deviceName, exampleQuestions }) => {
				if (exampleQuestions.length > 0) {
					requestBody.questions.push({
						key: `${manufacturerName}:${deviceName}`, // Dynamic key for manufacturerName:deviceName
						questions: exampleQuestions,
					});
				}
			}
		);
	}

	// Handle devices section (if deviceTypes is empty but devices are available)
	if (questionsObj.devices && questionsObj.devices.length > 0) {
		questionsObj.devices.forEach(({ deviceName, exampleQuestions }) => {
			if (exampleQuestions.length > 0) {
				requestBody.questions.push({
					key: `${deviceName}`, // Use deviceName as the key
					questions: exampleQuestions, // Questions for each device
				});
			}
		});
	}

	// Handle categoriesContainer section (if both deviceTypes and devices are empty)
	if (questionsObj.categories && questionsObj.categories.length > 0) {
		questionsObj.categories.forEach(
			({ categoryName, deviceName, exampleQuestions }) => {
				if (exampleQuestions.length > 0) {
					requestBody.questions.push({
						key: `${categoryName}:${deviceName}`, // Use categoryName:deviceName as the key
						questions: exampleQuestions, // Questions for each device in category
					});
				}
			}
		);
	}

	try {
		const response = await axios.post(url, requestBody);
		return response.data;
	} catch (error) {
		console.error("Error translating questions:", error);
		return [];
	}
};

export const  replaceQuestions=(questionsArray, newQuestions)=> {
	return questionsArray.map((item, index) => ({
		...item,
		question: newQuestions[index].trim(), // Remove leading spaces
	}));
}